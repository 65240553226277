import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import PopulationSection from "../components/Dashboard/PopulationSection";
import ProgressBarCard from "../components/Dashboard/ProgressBarCard";
import { totalWard } from "../data/config";
import { wardNumber } from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import SVG from "react-inlinesvg";
import {
	getAnaysisPopulation,
	getCummilativeData,
	getFewData,
	getMoreData,
} from "../modules/Dashboard/_redux/dashboardApi";
import PopulationTable from "../components/Dashboard/PopulationTable";
import PopulationDetailTable from "../components/PopulationDetailTable";

const Dashboard = (props) => {
	const [ward, setWard] = useState(0);
	const [sessionWard] = useState(parseInt(sessionStorage.getItem("ward")));
	const [allWards, setAllWards] = useState([]);
	const [analysisPopulation, setAnalysisPopulation] = useState([]);
	const [cummilativeData, setCummilativeData] = useState([]);
	const [fewData, setFewData] = useState([]);
	const [moreData, setMoreData] = useState([]);
	const [selectedWard, setselectedWard] = useState("दंगीशरण गाउँपालिका");

	useEffect(() => {
		fetchData();
		getCummilativeData()
			.then((res) => {
				if (res.data) {
					setCummilativeData(res.data);
				}
			})
			.catch((err) => {
				toast.error("An error occured while fetching cummilative data");
			});
	}, [ward]);
	useEffect(() => {
		console.log(Array(totalWard).keys());
		setAllWards([...Array(totalWard).keys()]);
		// fetchData()
	}, []);
	const fetchData = () => {
		let finalWard = sessionWard !== 0 ? sessionWard : ward;
		getAnaysisPopulation(finalWard)
			.then((res) => {
				if (res.data) {
					setAnalysisPopulation(res.data.population.data);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("An error occured while fetching population data");
			});
		getFewData(finalWard)
			.then((res) => {
				if (res.data) {
					setFewData(res.data);
				}
			})
			.catch((err) => {
				toast.error("An error occured while fetching few data");
			});
		getMoreData(finalWard)
			.then((res) => {
				if (res.data) {
					setMoreData(res.data);
				}
			})
			.catch((err) => {
				toast.error("An error occured while fetching more data");
			});
	};
	const getWardListItem = (wardId) => {
		if (wardId != 0) {
			return selectedLanguage !== "nepali" ? `Ward ${wardId}` : `वडा ${wardId}`;
		}
		return selectedLanguage !== "nepali" ? "Dangisharan" : `दंगीशरण गाउँपालिका`;
	};
	const handleSelect = (val) => {
		setWard(val);
		if (val != 0) {
			selectedLanguage !== "nepali"
				? setselectedWard(`Ward ${val}`)
				: setselectedWard(`वडा ${val}`);
		} else {
			selectedLanguage !== "nepali"
				? setselectedWard("Dangisharan")
				: setselectedWard(`दंगीशरण गाउँपालिका`);
		}
	};
	return (
		<>
			{sessionWard === 0 && (
				<div className="row mb-4">
					<div className="col-12 d-flex justify-content-end">
						<DropdownButton
							id="ward-selector"
							title={selectedWard}
							size="lg"
							block
							onSelect={handleSelect}
							className="mr-2"
						>
							<Dropdown.Item as="button" key={0} eventKey={0}>
								{getWardListItem(0)}
							</Dropdown.Item>
							{allWards.map((ward) => {
								return (
									<Dropdown.Item as="button" key={ward + 1} eventKey={ward + 1}>
										{getWardListItem(ward + 1)}
									</Dropdown.Item>
								);
							})}
						</DropdownButton>
					</div>
				</div>
			)}
			<div className="row mb-4 d-flex align-items-stretch">
				<div className="col-lg-6 col-xl-8 pb-5 pb-lg-0 d-flex">
					<Card className="w-100">
						<Card.Body>
							<div className="d-flex pb-8">
								<h2 className="font-weight-black pl-3 pt-6 text-primary">
									{selectedLanguage == "nepali"
										? `${selectedWard} जनसंख्या विवरण`
										: `${selectedWard} population detail`}
								</h2>
							</div>

							<PopulationDetailTable population={analysisPopulation} />
						</Card.Body>
					</Card>
				</div>
				<div className="col-lg-6 col-xl-4">
					<PopulationSection population={analysisPopulation} />
				</div>
			</div>
			<ProgressBarCard moreData={moreData} fewData={fewData} />
			{/* <PopulationTable wardData={cummilativeData} population={analysisPopulation} /> */}
		</>
	);
};

export default Dashboard;
